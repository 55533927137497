// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers

import { addPropertyControls, ControlType } from "framer"
import { motion } from "framer-motion"
import { useState } from "react"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export default function InputField(props) {
    const { tint, type, placeholder, onValueChange } = props
    const [inputValue, setInputValue] = useState("")

    const handleChange = (event) => {
        setInputValue(event.target.value)
        if (onValueChange) {
            onValueChange(event.target.value)
        }
    }

    return (
        <input
            type={type}
            id={name}
            name={name}
            placeholder={placeholder}
            required
            onChange={handleChange}
            style={{
                backgroundColor: tint,
                ...boxStyle,
            }}
        />
    )
}

addPropertyControls(InputField, {
    tint: {
        title: "Tint",
        type: ControlType.Color,
        defaultValue: "#FFF",
    },
    placeholder: {
        title: "PlaceHolder",
        type: ControlType.String,
        defaultValue: "Name",
    },
    name: {
        title: "Name",
        type: ControlType.String,
        defaultValue: "Name",
    },
    type: {
        title: "Type",
        type: ControlType.Enum,
        defaultValue: "text",
        options: ["text", "number", "password"],
        optionTitles: ["text", "number", "password"],
    },
})

// Styles are written in object syntax
// https://react.dev/reference/react-dom/components/common#usage
const boxStyle = {
    width: "100%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
}
